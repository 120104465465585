export const BYTESCALE_ID: string =
  import.meta.env.VITE_BYTESCALE_ID ??
  import.meta.env.BYTESCALE_ID ??
  process.env.VITE_BYTESCALE_ID ??
  process.env.BYTESCALE_ID ??
  ''

export const BYTESCALE_TOKEN: string =
  import.meta.env.VITE_BYTESCALE_TOKEN ??
  import.meta.env.BYTESCALE_TOKEN ??
  process.env.VITE_BYTESCALE_TOKEN ??
  process.env.BYTESCALE_TOKEN ??
  ''

export const FAUNA_GEOGRAPHY: string =
  import.meta.env.VITE_FAUNA_GEOGRAPHY ??
  import.meta.env.FAUNA_GEOGRAPHY ??
  process.env.VITE_FAUNA_GEOGRAPHY ??
  process.env.FAUNA_GEOGRAPHY ??
  ''

export const FAUNA_PUBLIC: string =
  import.meta.env.VITE_FAUNA_PUBLIC ??
  import.meta.env.FAUNA_PUBLIC ??
  process.env.VITE_FAUNA_PUBLIC ??
  process.env.FAUNA_PUBLIC ??
  ''

export const MAPBOX_TOKEN: string =
  import.meta.env.VITE_MAPBOX_TOKEN ??
  import.meta.env.MAPBOX_TOKEN ??
  process.env.VITE_MAPBOX_TOKEN ??
  process.env.MAPBOX_TOKEN ??
  ''

export const MAPBOX_PIN_URL: string =
  'https://upcdn.io/W142inX/raw/public/pin.png'
