import type { SlSelectEvent } from '@shoelace-style/shoelace'

import { LitElement, css, html } from 'lit'
import { customElement, state } from 'lit/decorators.js'
import { updateLocalStorage } from '@unilease/lib/utils/updateLocalStorage.js'

import '@shoelace-style/shoelace/dist/components/divider/divider.js'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js'
import '@shoelace-style/shoelace/dist/components/menu/menu.js'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js'

@customElement('app-menu-theme')
export class AppMenuTheme extends LitElement {
  static override styles = css`
    :host {
      align-items: center;
      display: inline-flex;
      height: var(--size, 4rem);
      justify-content: center;
      width: var(--size, 4rem);
    }
  `

  @state()
  private _theme = localStorage.getItem('theme')

  @state()
  private _icon: 'moon' | 'sun' = 'sun'

  private _prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

  private _handleSchemeChange = () => {
    this._theme = null
    this._updateTheme()
  }

  private _handleSelect = ({ detail }: SlSelectEvent) => {
    const { value } = detail.item
    this._theme = value || null
    this._updateTheme()
  }

  private _updateTheme = () => {
    if (this._theme === 'dark' || (!this._theme && this._prefersDark.matches)) {
      this._icon = 'moon'
      document.documentElement.classList.add('sl-theme-dark')
    } else {
      this._icon = 'sun'
      document.documentElement.classList.remove('sl-theme-dark')
    }

    updateLocalStorage('theme', this._theme)
  }

  override connectedCallback() {
    super.connectedCallback()
    this._prefersDark.addEventListener('change', this._handleSchemeChange)
  }

  override disconnectedCallback() {
    this._prefersDark.removeEventListener('change', this._handleSchemeChange)
    super.disconnectedCallback()
  }

  protected override firstUpdated() {
    this._updateTheme()
  }

  protected override render() {
    return html`<sl-dropdown
      distance="4"
      placement="left-end"
      @sl-select=${this._handleSelect}
    >
      <sl-icon-button
        style="font-size: 1.5rem;"
        slot="trigger"
        name=${this._icon}
        label="Theme"
      ></sl-icon-button>
      <sl-menu>
        <sl-menu-item
          ?checked=${this._theme === 'light'}
          type="checkbox"
          value="light"
          >Light</sl-menu-item
        >
        <sl-menu-item
          ?checked=${this._theme === 'dark'}
          type="checkbox"
          value="dark"
          >Dark</sl-menu-item
        >
        <sl-divider></sl-divider>
        <sl-menu-item type="checkbox" ?checked=${!this._theme}
          >System</sl-menu-item
        >
      </sl-menu>
    </sl-dropdown>`
  }
}
