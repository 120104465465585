import { LitElement, css, html } from 'lit'
import { customElement, query } from 'lit/decorators.js'

import '@shoelace-style/shoelace/dist/components/card/card.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@unilease/ui/components/ping.js'
import '@unilease/ui/components/mapbox-static.js'

import { styles as commonStyles } from '@unilease/ui/styles/common.css.js'

@customElement('app-home')
export class AppHome extends LitElement {
  static override styles = [
    commonStyles,
    css`
      main {
        padding: var(--sl-spacing-medium);
      }

      h2 {
        margin-top: 0;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: var(--sl-spacing-medium);
      }

      img {
        height: 12rem;
        width: 100%;
        object-fit: cover;
      }

      [slot='image'] {
        width: 100%;
      }

      sl-card::part(base) {
        height: 100%;
        min-height: 12rem;
      }

      @media (min-width: 768px) {
        main {
          padding: var(--sl-spacing-3x-large);
        }

        div {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
          gap: var(--sl-spacing-large);
        }
      }
    `,
  ]

  @query('.time', true)
  private _time!: HTMLSpanElement

  private _updateGreeting() {
    const hours = new Date().getHours()

    let time = 'morning'

    if (hours > 11 && hours < 17) time = 'afternoon'
    if (hours > 16) time = 'evening'

    this._time.textContent = time
  }

  protected override firstUpdated() {
    this._updateGreeting()
  }

  protected override render() {
    return html`
      <main>
        <h1>Good <span class="time">day</span>.</h1>

        <div>
          <sl-card>
            <a href="/leases/abc678" slot="image">
              <app-mapbox-static
                .longitude=${-90.022944}
                .latitude=${35.045944}
                .zoom=${14}
                .options=${{
                  height: 192,
                  width: 512,
                }}
              >
              </app-mapbox-static>
            </a>
            Graceland
          </sl-card>
          <sl-card class="ping">
            <app-ping></app-ping>
          </sl-card>
          <sl-card></sl-card>
          <sl-card></sl-card>
          <sl-card></sl-card>
          <sl-card></sl-card>
          <sl-card></sl-card>
          <sl-card></sl-card>
          <sl-card></sl-card>
          <!-- {data.data.map((project) => (
          <ProjectCard key="{project?.id}" project="{project}" />
          ))}
          <LoadMoreProjects after="{data.after}" /> -->
        </div>

        <p></p>
      </main>
    `
  }
}
