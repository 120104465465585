import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js'

export function registerLucideIconLibrary() {
  registerIconLibrary('default', {
    resolver: (name) => `/assets/lucide/icons/${name}.svg`,
    mutator: (svg) => {
      svg.setAttribute('stroke-width', '1.5')
      svg
        .querySelectorAll(
          'circle, ellipse, line, path, polygon, polyline, rect'
        )
        .forEach((path) =>
          path.setAttribute('vector-effect', 'non-scaling-stroke')
        )
    },
  })
}
