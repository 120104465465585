import { LitElement, css, html, type PropertyValues } from 'lit'
import { customElement, property, state, queryAll } from 'lit/decorators.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import unileaseLogo from '../images/unilease-logo.svg?raw'

import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/icon/icon.js'
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js'

import './menu-theme.js'
import './menu-user.js'

@customElement('app-navigation')
export class AppNavigation extends LitElement {
  static override styles = [
    css`
      :host {
        align-items: center;
        background-color: var(--sl-panel-background-color);
        bottom: 0;
        border-right: none;
        border-top: 1px solid var(--sl-panel-border-color);
        box-shadow: var(--sl-shadow-x-small);
        display: flex;
        flex-direction: row;
        gap: 1rem;
        height: 3rem;
        justify-content: space-between;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 1;
      }

      a > * {
        pointer-events: none;
      }

      nav,
      :host > div {
        align-items: center;
        display: flex;
        flex-direction: row;
      }

      :host > a {
        flex-shrink: 0;
        height: 4rem;
        width: 4rem;
      }

      :host > a,
      nav a {
        align-items: center;
        color: inherit;
        display: flex;
        height: 3rem;
        justify-content: center;
        position: relative;
        text-decoration: none;
        transition:
          background-color 200ms,
          color 200ms;
        user-select: none;
        width: 3rem;
      }

      :host > a:first-child > svg {
        width: 1.5rem;
      }

      nav a:focus,
      nav a:hover {
        background-color: var(--sl-color-neutral-100);
      }

      nav a.active {
        background-color: var(--sl-color-neutral-200);
      }

      nav a::after {
        background-color: var(--sl-color-primary-500);
        bottom: 0;
        content: '';
        height: 0.25rem;
        left: 0;
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms;
        width: 100%;
      }

      nav a:focus::after,
      nav a:hover::after,
      nav a.active::after {
        transform: scaleX(1);
      }

      sl-icon {
        font-size: 1.5rem;
      }

      app-menu-theme {
        --size: 3rem;
      }

      app-menu-user {
        --avatar-size: 2rem;
        --size: 3rem;
      }

      @media (min-width: 768px) {
        :host {
          flex-direction: column;
          height: 100dvh;
          width: 4rem;
          border-right: 1px solid var(--sl-panel-border-color);
          border-top: none;
        }

        :host > div,
        nav {
          flex-direction: column;
        }

        :host > a,
        nav a {
          height: 4rem;
          width: 4rem;
        }

        nav a::after {
          height: 100%;
          top: 0;
          transform: scaleY(0);
          width: 0.25rem;
        }

        nav a:focus::after,
        nav a:hover::after,
        nav a.active::after {
          transform: scaleY(1);
        }

        app-menu-theme {
          --size: 3rem;
        }

        app-menu-user {
          --avatar-size: 2.5rem;
          --size: 4rem;
          padding-bottom: 1rem;
        }
      }
    `,
  ]

  private _mediaQueryMedium = window.matchMedia('(min-width: 768px)')

  @property({ type: String })
  pathname = '/'

  @state()
  private _isMedium = this._mediaQueryMedium.matches

  @queryAll('nav a')
  private _navLinks!: NodeListOf<HTMLAnchorElement>

  private _onMediaQuery = () => {
    this._isMedium = this._mediaQueryMedium.matches
  }

  private _pathnameChanged = () => {
    this._navLinks.forEach((el) => {
      const href = el.getAttribute('href') ?? ''
      const active =
        href === '/' ? this.pathname === '/' : this.pathname.startsWith(href)
      if (!active && el.classList.contains('active')) {
        el.classList.remove('active')
      } else if (active) {
        el.classList.add('active')
      }
    })
  }

  override firstUpdated() {
    this._pathnameChanged()
  }

  override connectedCallback() {
    super.connectedCallback()
    this._mediaQueryMedium.addEventListener('change', this._onMediaQuery)
  }

  override disconnectedCallback() {
    this._mediaQueryMedium.removeEventListener('change', this._onMediaQuery)
    super.disconnectedCallback()
  }

  override updated(_changedProperties: PropertyValues) {
    if (_changedProperties.get('pathname')) this._pathnameChanged()
  }

  protected override render() {
    return html`
      <a href="/">${unsafeSVG(unileaseLogo)}</a>
      <nav>
        <sl-tooltip
          content="Dashboard"
          placement="right"
          ?disabled=${!this._isMedium}
        >
          <a href="/">
            <sl-icon name="house"></sl-icon>
          </a>
        </sl-tooltip>
        <sl-tooltip
          content="Projects"
          placement="right"
          ?disabled=${!this._isMedium}
        >
          <a href="/projects">
            <sl-icon name="folder-open"></sl-icon>
          </a>
        </sl-tooltip>
        <sl-tooltip
          content="Discussions"
          placement="right"
          ?disabled=${!this._isMedium}
        >
          <a href="/discussions">
            <sl-icon name="messages-square"></sl-icon>
          </a>
        </sl-tooltip>
      </nav>
      <div>
        <app-menu-theme></app-menu-theme>
        <app-menu-user></app-menu-user>
      </div>
    `
  }
}
