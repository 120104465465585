import type { SaveEvent } from '@unilease/types/events'
import type { SlSelectEvent } from '@shoelace-style/shoelace'
import type { DocumentT, User } from '@unilease/types/schema'

import { LitElement, css, html, nothing } from 'lit'
import { Task } from '@lit/task'
import { customElement } from 'lit/decorators.js'
import { fql } from 'fauna'
import { runQuery } from '@unilease/lib/utils/runQuery.js'
import { mediaUrl } from '@unilease/lib/utils/mediaUrl.js'

import '@shoelace-style/shoelace/dist/components/avatar/avatar.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'
import '@shoelace-style/shoelace/dist/components/icon/icon.js'
import '@shoelace-style/shoelace/dist/components/menu/menu.js'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js'

@customElement('app-menu-user')
export class AppMenuUser extends LitElement {
  static override styles = css`
    :host {
      align-items: center;
      display: flex;
      height: var(--size, 4rem);
      justify-content: center;
      width: var(--size, 4rem);
    }

    sl-avatar {
      --size: var(--avatar-size, 2.5rem);
    }

    sl-avatar::part(base) {
      background-color: var(--sl-panel-background-color);
      color: var(--sl-color-neutral-1000);
    }

    sl-button {
      box-sizing: content-box;
    }

    sl-button::part(base) {
      height: var(--avatar-size);
      width: var(--avatar-size);
      min-height: auto;
    }

    sl-button[slot='trigger']::part(label) {
      line-height: normal;
      padding: 0;
    }

    a {
      display: block;
      color: inherit;
      text-decoration: none;
    }
  `

  private _userId?: string

  private _getUserTask = new Task(this, {
    task: async () => {
      /**
       * Fauna Bug: Avoids typechecking error for nested `filePath` projection if`avatar` is null
       * https://discord.com/channels/826528159389581342/826528271998910464/1260706270021947512
       */
      const { data } = await runQuery<DocumentT<User>>(
        fql`
          let user = getUser()
          let avatar = if (user!.avatar != null) {
            let avatar: Any = user!.avatar
            avatar { filePath }
          } else {
            null
          }

          user {
            id,
            avatar: avatar,
            email,
            name,
          }
        `
      )

      this._userId = data.id

      return data
    },

    args: () => [],
  })

  private _handleSave = ({ detail }: SaveEvent) => {
    if (detail.id === this._userId) void this._getUserTask.run()
  }

  private _handleSelect = ({ detail }: SlSelectEvent) => {
    if (detail.item.dataset.href) {
      document.dispatchEvent(
        new CustomEvent('navigate', {
          detail: {
            url: detail.item.dataset.href,
          },
        })
      )
    }
  }

  override connectedCallback(): void {
    super.connectedCallback()
    document.addEventListener('save', this._handleSave)
  }

  override disconnectedCallback(): void {
    document.removeEventListener('save', this._handleSave)
    super.disconnectedCallback()
  }

  protected override render() {
    const { avatar, email, name } = this._getUserTask.value ?? {}
    const { filePath } = avatar ?? {}
    const initial = (name ?? email ?? '').slice(0, 1)

    return html`
      <sl-dropdown
        distance="4"
        placement="left-end"
        @sl-select=${this._handleSelect}
      >
        <sl-button slot="trigger" circle>
          <sl-avatar
            image=${mediaUrl(filePath, {
              transformation: 'preset',
              transformationPreset: 'avatar',
            }) ?? nothing}
            label=${name ?? email ?? ''}
            initials=${initial}
            ><sl-icon slot="icon" name="user-round"></sl-icon
          ></sl-avatar>
        </sl-button>

        <sl-menu>
          <sl-menu-item data-href="/account">
            <sl-icon slot="prefix" name="settings"></sl-icon>
            Account Settings
          </sl-menu-item>
          <sl-menu-item data-href="/logout">
            <sl-icon slot="prefix" name="log-out"></sl-icon>
            Log Out
          </sl-menu-item>
        </sl-menu>
      </sl-dropdown>
    `
  }
}
