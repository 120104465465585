import type { Feature } from 'geojson'
import type { StaticMapOptions } from './staticMapUrl.js'
import { staticMapUrl } from './staticMapUrl.js'

/**
 * Styles and generates overlay from GeoJSON before creating a static map URL
 */

interface FeatureStyles {
  'fill'?: string
  'fill-opacity'?: number
  'marker-color'?: string
  'marker-size'?: 'small' | 'medium' | 'large'
  'stroke'?: string
  'stroke-opacity'?: number
  'stroke-width'?: number
}

export function staticPolygonMapUrl({
  feature,
  featureStyles = {
    'stroke': '#fff',
    'stroke-width': 3,
    'fill': 'none',
  },
  options,
}: {
  feature: Feature
  featureStyles?: FeatureStyles
  options?: StaticMapOptions
}) {
  const styledFeature: Feature = {
    ...feature,
    properties: featureStyles,
  }

  const optionsWithOverlay = {
    ...options,
    overlay: `geojson(${encodeURIComponent(JSON.stringify(styledFeature))})`,
  }

  return staticMapUrl(optionsWithOverlay)
}
