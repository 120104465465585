import { LitElement, css, html } from 'lit'
import { Task } from '@lit/task'
import { customElement } from 'lit/decorators.js'

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js'

interface Ping {
  ping: string
  method: string
  time: string
}

@customElement('app-ping')
export class AppPing extends LitElement {
  static override styles = css`
    :host {
      display: content;
    }

    pre {
      display: block;
      margin: 0;
    }

    span,
    sl-spinner {
      vertical-align: middle;
    }

    sl-spinner {
      font-size: var(--sl-font-size-large);
    }
  `
  private _pingTask = new Task(this, {
    task: async (_, { signal }) => {
      const res = await fetch('/api/ping', { signal })
      if (!res.ok) throw new Error(res.status.toString())

      return res.json() as Promise<Ping>
    },

    args: () => [],
  })

  protected override render() {
    return this._pingTask.render({
      pending: () =>
        html`<pre><sl-spinner></sl-spinner> <span>Pinging…</span></pre>`,
      error: (e) => html`<p>Error: ${e}</p>`,
      complete: (ping) => html`<pre>${JSON.stringify(ping, null, 2)}</pre>`,
    })
  }
}
