import type { Router } from '@thepassle/app-tools/router.js'
import type { NavigateEvent } from '@unilease/types/events'

import { LitElement, html, css } from 'lit'
import { customElement, state } from 'lit/decorators.js'
import { registerLucideIconLibrary } from '@unilease/ui/helpers/icons.js'
import { registerSW } from 'virtual:pwa-register'
import { router } from './router.js'
import { userCoords } from '@unilease/lib/utils/userCoords.js'

import '@unilease/ui/components/navigation.js'
import '@unilease/ui/styles/base.css'

@customElement('app-index')
export class AppIndex extends LitElement {
  static override styles = css`
    :host {
      display: contents;
    }

    div {
      display: block;
      margin: 0;
    }

    div:has(app-navigation) {
      margin: 0 0 3rem 0;
    }

    @media (min-width: 768px) {
      div:has(app-navigation) {
        margin: 0 0 0 4rem;
      }
    }
  `
  private _isLoggedIn = () => document.cookie.includes('session=')

  @state()
  private _pathname = '/'

  constructor() {
    super()
    registerSW({ immediate: true })
    registerLucideIconLibrary()
    void userCoords()
  }

  protected override firstUpdated() {
    router.addEventListener('route-changed', (e: Event) => {
      const { pathname } = (e.target as Router)?.context?.url ?? {}
      this._pathname = pathname
      this.requestUpdate()
    })

    document.addEventListener('navigate', ({ detail }: NavigateEvent) => {
      const { url, backNav } = detail
      void router.navigate(url, { backNav })
    })
  }

  protected override render() {
    return html`
      <div>
        ${this._isLoggedIn()
          ? html`<app-navigation pathname=${this._pathname}></app-navigation>`
          : null}
        ${router.render()}
      </div>
    `
  }
}
