import type { UrlBuilderOptions } from '@bytescale/sdk'
import { UrlBuilder } from '@bytescale/sdk'
import { BYTESCALE_ID } from '../constants'

/**
 * Transform media using Bytescale's Processing APIs
 * https://www.bytescale.com/docs/sdks/javascript#building-urls
 */

export function mediaUrl(
  filePath?: string | null,
  options?: UrlBuilderOptions
) {
  if (!filePath) return ''

  return UrlBuilder.url({
    accountId: BYTESCALE_ID,
    filePath,
    options,
  })
}
