/**
 * App Tools Router
 * https://github.com/thepassle/app-tools/blob/master/router/README.md
 */

import { html } from 'lit'
import { Router } from '@thepassle/app-tools/router.js'
import { appName } from '@thepassle/app-tools/router/plugins/appName.js'
import { checkServiceWorkerUpdate } from '@thepassle/app-tools/router/plugins/checkServiceWorkerUpdate.js'
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js'
import { scrollToTop } from '@thepassle/app-tools/router/plugins/scrollToTop.js'

import './home.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
if (!(globalThis as any).URLPattern) await import('urlpattern-polyfill')

const protectedRoute = {
  name: 'protectedRoute',
  shouldNavigate: () => ({
    condition: () => document.cookie.includes('session='),
    redirect: '/login',
  }),
}

const unprotectedRoute = {
  name: 'unprotectedRoute',
  shouldNavigate: () => ({
    condition: () => true,
    redirect: '/',
  }),
}

export const router = new Router({
  plugins: [
    protectedRoute,
    checkServiceWorkerUpdate,
    scrollToTop,
    appName('Unilease | '),
  ],
  fallback: '/404',
  routes: [
    {
      path: '/',
      title: 'Lease with confidence',
      render: () => html`<app-home></app-home>`,
    },
    {
      path: '/404',
      title: 'Not found',
      plugins: [unprotectedRoute, lazy(() => import('./not-found.js'))],
      render: () => html`<app-not-found></app-not-found>`,
    },
    {
      path: '/account',
      title: 'Account',
      plugins: [lazy(() => import('./account.js'))],
      render: () => html`<app-account></app-account>`,
    },
    {
      path: '/discussions',
      title: 'Discussions',
      plugins: [lazy(() => import('./discussions.js'))],
      render: () => html`<app-discussions></app-discussions>`,
    },
    {
      path: '/leases/:id',
      title: 'Lease',
      plugins: [lazy(() => import('./leases.js'))],
      render: ({ params }) =>
        html`<app-leases project-id="${params.id ?? ''}"></app-leases>`,
    },
    {
      path: '/login',
      title: 'Log in',
      plugins: [unprotectedRoute, lazy(() => import('./login.js'))],
      render: () => html`<app-login></app-login>`,
    },
    {
      path: '/logout',
      title: 'Log out',
      plugins: [unprotectedRoute, lazy(() => import('./logout.js'))],
      render: () => html`<app-logout></app-logout>`,
    },
    {
      path: '/projects',
      title: 'Projects',
      plugins: [lazy(() => import('./projects.js'))],
      render: () => html`<app-projects></app-projects>`,
    },
    {
      path: '/properties/:id',
      title: 'Property',
      plugins: [lazy(() => import('./properties.js'))],
      render: ({ params }) =>
        html`<app-properties project-id="${params.id ?? ''}"></app-properties>`,
    },
    {
      path: '/spaces/:id',
      title: 'Space',
      plugins: [lazy(() => import('./spaces.js'))],
      render: ({ params }) =>
        html`<app-spaces project-id="${params.id ?? ''}"></app-spaces>`,
    },
    {
      path: '/surveys/:id',
      title: 'Survey',
      plugins: [lazy(() => import('./surveys.js'))],
      render: ({ params }) =>
        html`<app-surveys project-id="${params.id ?? ''}"></app-surveys>`,
    },
  ],
})
