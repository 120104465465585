import type { Geo } from '@unilease/types/vercel'
import { updateLocalStorage } from './updateLocalStorage'

/**
 * Gets user geolocation ands sets `coords` in local storage
 */
export async function userCoords() {
  const res = await fetch('/api/auth/geolocation')

  if (!res.ok) throw new Error(res.status.toString())

  const geo = (await res.json()) as Geo
  const { latitude, longitude } = geo
  const coords: [number, number] = [Number(longitude), Number(latitude)]

  updateLocalStorage('coords', JSON.stringify(coords), 'session')

  return coords
}
