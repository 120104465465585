/**
 * Updates `localStorage` and dispatches StorageEvent. Pass a `null` value to
 * delete an item
 */
export function updateLocalStorage(
  key: string,
  value: string | null,
  storage?: 'local' | 'session'
): void {
  const storageArea: Storage =
    storage === 'session' ? sessionStorage : localStorage

  const oldValue = storageArea.getItem(key)

  if (value === null) {
    storageArea.removeItem(key)
  } else {
    storageArea.setItem(key, value)
  }

  const storageEvent = new StorageEvent('storage', {
    key: key,
    oldValue: oldValue,
    newValue: value,
    url: window.location.href,
    storageArea,
  })

  window.dispatchEvent(storageEvent)
}
