import { css } from 'lit'

export const styles = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  ::selection {
    background-color: var(--sl-color-primary-200);
    color: var(--sl-color-neutral-900);
  }

  /* :not(:defined),
  :not(:defined) * {
    opacity: 0;
  }

  :defined {
    opacity: 1;
    transition: 50ms opacity;
  } */

  table {
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
  }

  th,
  td {
    margin: 0;
    padding: var(--sl-spacing-2x-small) var(--sl-spacing-medium);
    vertical-align: baseline;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  th {
    color: var(--sl-color-neutral-600);
    font-size: var(--sl-font-size-small);
    font-weight: var(--sl-font-weight-normal);
    text-align: left;
  }

  td {
    border-top: 1px solid var(--sl-input-border-color);
    white-space: nowrap;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  dl > dt,
  dl > dd {
    margin: 0;
    width: 50%;
  }

  dl > dd:empty::before {
    content: '-';
  }

  dl > *:not(:first-of-type) {
    padding-top: var(--sl-spacing-x-small);
  }

  dl > *:not(:last-of-type) {
    border-bottom: 1px solid var(--sl-input-border-color);
  }

  dl > * {
    padding-bottom: var(--sl-spacing-x-small);
  }

  .text-align-right {
    text-align: right;
  }

  a {
    color: var(--sl-color-primary-600);
    text-decoration: none;
    transition: color var(--sl-transition-x-fast);
    word-wrap: break-word;
  }

  a[href=''] {
    display: none;
  }

  a:focus,
  a:hover {
    color: var(--sl-color-primary-500);
    text-decoration: underline;
  }

  a:focus,
  button:focus {
    outline: none;
  }

  a:focus-visible,
  button:focus-visible {
    outline: var(--sl-focus-ring);
    outline-offset: var(--sl-focus-ring-offset);
  }

  hr {
    border: none;
    border-top: 1px solid var(--sl-input-border-color);
    margin: 3rem 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  svg {
    display: block;
  }

  sl-card {
    --border-color: var(--sl-panel-border-color);
  }

  sl-card::part(image) {
    margin: 0;
  }
`
