import type { Query, QueryOptions, QueryValue } from 'fauna'
import { Client, FaunaError } from 'fauna'
import { FAUNA_PUBLIC } from '../constants.js'
import { parse } from 'cookie'

/**
 * Runs a browser Fauna query and closes the client on completion
 * - The client uses FAUNA_PUBLIC env by default
 * - The `secret` variable in query options overwrites the default secret
 */
export async function runQuery<TQueryValue extends QueryValue | FaunaError>(
  query: Query,
  options?: QueryOptions
) {
  const { session } = parse(document.cookie ?? '')
  const client = new Client({ secret: session ?? FAUNA_PUBLIC })

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return await client.query<TQueryValue>(query, options)
  } finally {
    client.close()
  }
}
