import { MAPBOX_TOKEN } from '../constants.js'

/**
 * Generates a static map URL using the Mapbox Static Images API
 * https://docs.mapbox.com/api/maps/static-images
 *
 * URL structure:
 * https://api.mapbox.com/styles/v1/{username}/{style_id}/static/{overlay}/{lon},{lat},{zoom},{bearing},{pitch}|{bbox}|{auto}/{width}x{height}{@2x}
 */
export interface StaticMapOptions {
  attr?: boolean
  center?: string
  height?: number
  overlay?: string
  padding?: number
  retina?: boolean
  width?: number
  style?:
    | 'dark-v11'
    | 'light-v11'
    | 'outdoors-v12'
    | 'satellite-streets-v12'
    | 'satellite-v9'
    | 'streets-v12'
}

export function staticMapUrl({
  attr = false,
  center = 'auto',
  height = 512,
  overlay = '',
  padding = 32,
  retina = true,
  width = 512,
  style = 'satellite-streets-v12',
}: StaticMapOptions) {
  const url = new URL('https://api.mapbox.com')

  url.pathname = [
    '/styles/v1/mapbox',
    style,
    'static',
    overlay,
    center,
    `${String(width)}x${String(height)}${retina ? '@2x' : ''}`,
  ]
    .filter((val) => val)
    .join('/')

  if (padding && center === 'auto') {
    url.searchParams.set('padding', String(padding))
  }

  if (!attr) {
    url.searchParams.set('attribution', String(false))
    url.searchParams.set('logo', String(false))
  }

  url.searchParams.set('access_token', MAPBOX_TOKEN)

  return url.toString()
}
