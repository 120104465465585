import type { Feature } from 'geojson'
import type { StaticMapOptions } from '@unilease/lib/utils/staticMapUrl.js'

import { LitElement, html, css } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'
import { MAPBOX_PIN_URL } from '@unilease/lib/constants.js'
import { staticMapUrl } from '@unilease/lib/utils/staticMapUrl.js'
import { staticPolygonMapUrl } from '@unilease/lib/utils/staticPolygonMapUrl.js'

@customElement('app-mapbox-static')
export class AppMapboxStatic extends LitElement {
  static override styles = css`
    :host {
      display: contents;
    }

    img {
      color: transparent;
      object-fit: cover;
      opacity: 0;
      width: 100%;
      transition:
        opacity var(--sl-transition-fast),
        transform var(--sl-transition-fast);
    }

    img.loaded {
      opacity: 1;
    }
  `

  @property({ type: String })
  alt = ''

  @property({ type: Object })
  feature: Feature | null = null

  @property({ reflect: true, type: Boolean })
  loaded = false

  @property({ type: Number })
  longitude = 0

  @property({ type: Number })
  latitude = 0

  @property({ type: Number })
  zoom = 16

  @property({ type: Object })
  options: StaticMapOptions = { height: 512, width: 512, padding: 16 }

  @query('img', true)
  private _img!: HTMLImageElement

  protected override firstUpdated() {
    this._img.addEventListener(
      'load',
      () => this._img.classList.add('loaded'),
      {
        once: true,
      }
    )
  }

  protected override render() {
    const src = this.feature
      ? staticPolygonMapUrl({ feature: this.feature, options: this.options })
      : staticMapUrl({
          ...this.options,
          center: [this.longitude, this.latitude, this.zoom].toString(),
          overlay: `url-${encodeURIComponent(String(MAPBOX_PIN_URL))}(${String(this.longitude)},${String(this.latitude)})`,
          padding: 0,
        })

    return html`
      <img
        alt=${this.alt}
        height=${String(this.options.height)}
        loading="lazy"
        part="img"
        src=${src}
        width=${String(this.options.width)}
      />
    `
  }
}
